<template>
  <div class="index">
    <div class="index-left">
      <ul>
        <li>
          <router-link to="/home/individuation"><span class="iconfont icon-airec-zhinengtuijian"></span><span>个性推荐</span></router-link>
        </li>
        <li>
          <router-link to="/recommend"><span class="iconfont icon-yinleliebiao"></span><span>歌单推荐</span></router-link>
        </li>
        <li>
          <router-link to="/songs"><span class="iconfont icon-yinyue"></span><span>最新音乐</span></router-link>
        </li>
        <li>
          <router-link to="/mv"><span class="iconfont icon-MV"></span><span>最新MV</span></router-link>
        </li>
        <li>
          <router-link to="/leaderboard"><span class="iconfont icon-yinle"></span><span>排行榜</span></router-link>
        </li>
        <li>
          <router-link to="/singer"><span class="iconfont icon-geshou"></span><span>歌手</span></router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Aside'
}
</script>

<style lang="less">
.index {
  overflow: hidden;
  .index-left {
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        width: 100%;
        cursor: pointer;
        letter-spacing: 0.1rem;
        height: 60px;
        line-height: 60px;
        padding-left: 70px;
        span {
          margin-right: 10px;
        }
      }
      li:hover {
        background-color: #ecf5ff;
      }
      a {
        text-decoration: none;
        color: #333;
        display: block;
        width: 100%;
        height: 100%;
        font-size: 16px;
        text-align: left;
      }
      a::before {
        margin-right: 10px;
      }
    }
    .router-link-active {
      color: #ff0000 !important;
    }
  }
}
@media screen and (min-width: 480px) and (max-width: 960px) {
  .index-left ul li {
    padding-left: 20px !important;
  }
}
@media screen and (max-width: 479px) {
  .index-left ul li {
    padding: 0 !important;
  }
  .index-left ul li a {
    margin-left: 15px;
  }
  .index-left ul li a span:last-child {
    display: none;
  }
  .index-left ul li a span:first-child {
    font-size: 20px;
  }
}
</style>
