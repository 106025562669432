<template>
  <el-container class="all">
    <el-header>
      <Header />
    </el-header>
    <el-container>
      <el-aside>
        <Aside />
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <Bottom />
    <div class="record">
      <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021008567号-2</a>
    </div>

  </el-container>
</template>

<script>
import Header from './children/Header'
import Aside from './children/Aside'
import Bottom from './children/Bottom'

export default {
  components: {
    Header,
    Aside,
    Bottom
  }
}
</script>

<style scoped>
.all {
  display: flex;
  width: 100vw;
}

.el-container {
  height: 100%;
  overflow: hidden;
}

.el-header {
  background-color: rgba(249, 249, 249, 0.5);
  box-shadow: 0 1px 1px red;
  margin-bottom: 2px;
}

.el-aside {
  border-right: 1px solid rgba(200, 200, 200, 0.3);
  width: 180px !important;
}

.record {
  text-align: center;
  font-size: 20px;
  padding: 10px;
  color: #1d3154;
}

@media screen and (min-width: 480px) and (max-width: 960px) {
  .el-aside {
    width: 110px !important;
  }
}

@media screen and (max-width: 479px) {
  .el-aside {
    width: 50px !important;
  }

  .el-header {
    padding: 0;
    z-index: 9;
  }

  .el-main {
    padding: 5px;
  }
}
</style>
