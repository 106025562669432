<template>
  <div class="Header">
    <ul class="headerul">
      <li>
        <i @click="toSite(url)"
           class="iconfont icon-wangyiyunyinle"
           style="cursor: pointer"></i>
      </li>
      <li>
        <el-button type="danger"
                   size="mini"
                   class="clear-icon el-icon-arrow-left"
                   @click="go(-1)"
                   circle></el-button>
        <el-button type="danger"
                   size="mini"
                   class="clear-icon el-icon-arrow-right"
                   @click="go(1)"
                   circle></el-button>
      </li>
    </ul>
    <el-input placeholder="想听什么歌呢"
              v-model="searchValue"
              clearable
              @change="toSearch"
              @focus="getSearchHot"
              @blur="showHot = false">
      <i slot="prefix"
         @click="toSearch"
         class="el-input__icon el-icon-search"></i>
    </el-input>
    <div class="search-hot"
         v-show="showHot"
         ref="hot"
         @mousedown.prevent>
      <el-scrollbar style="height: 100%">
        <div class="history"
             v-if="history.length">
          <span class="hot-title"
                style="display: inline-block; margin-right: 5px">搜索历史</span>
          <span class="iconfont icon-lajitong"
                style="cursor: pointer"
                title="清空搜索历史"
                @mousedown="deleteHistory(0, true)"></span>
          <div class="history-wrap">
            <div class="history-item"
                 v-for="(item, index) in history"
                 :key="index"
                 @mousedown="toHot(item)">
              {{ item }}
              <span class="delete-btn"
                    title="删除"
                    @mousedown.stop="deleteHistory(index, false)">×</span>
            </div>
          </div>
        </div>

        <div class="hot-title">热搜榜</div>
        <ul>
          <li class="hot-item"
              v-for="(item, index) in hotData"
              :key="index"
              @mousedown.prevent="toHot(item.searchWord)">
            <div class="hot-index">{{ index + 1 }}</div>
            <div class="hot-info">
              <div class="hot-top">
                <div class="hot-name">{{ item.searchWord }}</div>
                <div class="hot-score">{{ item.score }}</div>
                <img class="hot-icon"
                     v-show="item.iconUrl && item.iconType != 5"
                     :src="item.iconUrl" />
              </div>
              <div class="hot-content">{{ item.content }}</div>
            </div>
          </li>
        </ul>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { hotSearchAPI } from '@/api/api'
export default {
  name: 'Header',
  data () {
    return {
      url: 'https://gitee.com/xiong-haodong',
      searchValue: '',
      showHot: false,
      hotData: [],
      history: []
    }
  },
  methods: {
    toSite (e) {
      window.location.href = e
    },
    go (index) {
      window.history.go(index)
    },
    isNull (str) {
      if (str === '') return true
      var regu = '^[ ]+$'
      var re = new RegExp(regu)
      return re.test(str)
    },
    toSearch () {
      if (this.isNull(this.searchValue)) {
        return this.$message.error({
          message: '要输入歌名哦~',
          center: true,
          showClose: true
        })
      } else {
        this.$router.push({ path: `/result?keywords=${this.searchValue}` })
        if (!this.history.includes(this.searchValue)) {
          this.history.push(this.searchValue)
        }
      }
    },
    async getSearchHot () {
      this.showHot = true
      if (this.hotData.length === 0) {
        const { data: res } = await hotSearchAPI()
        this.hotData = res.data
      }
    },
    toHot (value) {
      this.searchValue = value
      this.toSearch()
      this.showHot = false
    },
    deleteHistory (index, all) {
      if (all) {
        this.history = []
      } else {
        for (let i = 0; i < this.history.length; i++) {
          if (i === index) {
            this.history.splice(i, 1)
          }
        }
      }
      event.preventDefault()
    }
  }
}
</script>

<style scoped lang="less">
.Header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headerul {
    height: 60px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      &:first-child {
        i {
          color: #ff0000;
          font-size: 32px;
          margin-left: 10px;
        }
      }

      &:nth-child(2) {
        .el-button {
          font-size: 12px;
        }
      }
    }
  }
  .el-input {
    width: 240px !important;
    margin-right: 30px;
  }
  /deep/.el-input__inner {
    height: 40px;
    border-radius: 20px !important;
  }
}

.search-hot {
  width: 350px;
  height: 400px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  top: 55px;
  right: 30px;
  z-index: 9;
  padding: 10px;
  box-sizing: border-box;
}

.hot-title {
  margin: 10px 0;
  font-weight: bold;
  flex-wrap: wrap;
  ul {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.hot-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
}

.hot-item:hover {
  background-color: seashell;
}

.hot-index {
  font-size: 20px;
  color: #ff0000;
  margin-right: 15px;
}

.hot-info {
  flex: 1;
  font-size: 12px;
  color: slategray;
}

.hot-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.hot-name {
  font-size: 16px;
  color: #000;
}

.hot-icon {
  width: 30px;
  margin-left: 5px;
}

.history-wrap {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.history-item {
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  margin: 5px;
}

.history-item:hover {
  background-color: #f5f7fa;
}

.history-item:hover .delete-btn {
  opacity: 1;
}

.delete-btn {
  cursor: pointer;
  opacity: 0;
}
@media screen and (min-width: 480px) and (max-width: 960px) {
  li i:first-child {
    font-size: 26px !important;
  }
  li .el-button {
    width: 26px;
    height: 26px;
  }
  .Header .headerul {
    width: 230px;
  }
}
@media screen and (max-width: 479px) {
  .Header .headerul li > i {
    font-size: 25px !important;
  }
  li .el-button {
    display: none;
  }
  .Header .headerul {
    width: 260px;
  }
  .Header {
    padding: 0 !important;
  }
  .search-hot {
    width: 190px;
    height: 300px;
    padding: 2vw 0 0 2vw;
    margin-right: -4vw;
  }
  .hot-title {
    margin: 5px 0;
  }
  .hot-index {
    font-size: 14px;
    margin-right: 10px;
    margin-left: -5px;
  }
  .hot-info {
    font-size: 10px;
  }
  .hot-name {
    font-size: 13px;
  }
  .Header /deep/ .el-input__inner {
    height: 30px !important;
    width: 45vw;
  }
  .el-input {
    margin-right: 0 !important;
  }
  /deep/ .el-input__icon {
    line-height: 30px;
  }
  /deep/ .el-input .el-input__clear {
    margin-right: 3vw;
  }
  .history-item {
    padding: 1vw 0 1vw 2vw;
    font-size: 12px;
  }
}
</style>
