<template>
  <transition leave-active-class="closeWindow">
    <div class="song-detail"
         v-show="!show"
         ref="bg">
      <i class="el-icon-arrow-down"
         @click="close"></i>
      <div class="content">
        <div class="img-box">
          <img :src="playerBar"
               :class="{'player-bar isplay':!isMusicPaused,'player-bar':isMusicPaused}">
          <div class="img-wrap-rotate">
            <img :src="globalMusicInfo.imgUrl?globalMusicInfo.imgUrl:defaultImgUrl"
                 :class="{'running':!isMusicPaused,'paused':isMusicPaused}"
                 ref="cover">
          </div>
        </div>

        <div class="song-wrap">
          <h2>{{globalMusicInfo.songName}}</h2>
          <div class="song-info">
            <span v-for="(singer,i) in globalMusicInfo.artistInfo"
                  :key="i">{{singer.name}} </span>
          </div>
          <div class="lyric-wrap">
            <el-scrollbar style="height:100%"
                          v-if="hasLyric"
                          ref="scroll">
              <p v-for="(item,index) in lyric"
                 :key="index"
                 :class="{'active-lyric':currentIndex===index}">{{item.lyricWords}}</p>
            </el-scrollbar>
            <p v-if="!hasLyric">抱歉，暂无歌词</p>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import { lyricAPI } from '@/api/api'

export default {
  data () {
    return {
      playerBar: require('@/assets/img/player_bar.png'),
      defaultImgUrl: require('@/assets/img/defaultImg.png'),
      lyric: [],
      currentIndex: 0,
      hasLyric: true
    }
  },
  props: {
    show: Boolean
  },
  methods: {
    close () {
      this.show = true
    },
    async getLyric (id) {
      const { data: res } = await lyricAPI({ id })
      const _this = this
      if (!Object.hasOwnProperty.call(res, 'lrc')) {
        _this.hasLyric = false
      } else {
        this.hasLyric = true
        const lyricStr = res.lrc.lyric
        const lyricList = lyricStr.split('\n')
        const lyric = []
        for (const item of lyricList) {
          const end = item.indexOf(']')
          const time = item.slice(1, end)
          const currentTime = this.timeFormat(time)

          // 截取歌词,正则表达式去除[]及其包括的内容
          const lyricWords = item.replace(/\[.*?\]/g, '')
          if (lyricWords) {
            lyric.push({
              currentTime,
              lyricWords
            })
          }
        }
        this.lyric = lyric
      }
    },
    timeFormat (time) {
      const minute = parseInt(time.split(':')[0])
      const second = Math.ceil(time.split(':')[1])
      const currentTime = minute * 60 + second
      return currentTime
    },
    lryicActive (time) {
      for (let i = 0; i < this.lyric.length; i++) {
        if (this.lyric[i].currentTime === Math.ceil(time)) {
          const _p = document.querySelector('.active-lyric')
          const _pOffsetTop = _p.offsetTop
          this.$refs.scroll.wrap.scrollTop = _pOffsetTop - _p.offsetHeight * 3 // 想滚到哪个高度，就写多少
          this.currentIndex = i
        }
      }
    }
  },
  computed: {
    globalMusicInfo () {
      return this.$store.state.globalMusicInfo
    },
    globalCurrentTime () {
      return this.$store.state.globalCurrentTime
    },
    isMusicPaused () {
      return this.$store.state.isMusicPaused
    },
    globalMusicUrl () {
      return this.$store.state.globalMusicUrl
    }
  },
  watch: {
    globalCurrentTime () {
      if (this.hasLyric) {
        // 解决没有歌词仍会滚动的bug
        this.lryicActive(this.globalCurrentTime)
      }
    },
    globalMusicUrl () {
      if (this.globalMusicUrl !== '') {
        const id = this.$store.state.globalMusicInfo.id
        this.getLyric(id)
      }
    }
  }
}
</script>

<style>
ul {
  list-style: none;
}

.song-detail {
  z-index: 10;
  width: 100%;
  height: calc(100% - 60px);

  /* 歌词界面全屏 */

  background: linear-gradient(to left top, rgb(60, 50, 62), rgb(77, 70, 88));
  position: fixed;
  top: 100%;
  color: #fff;

  animation-duration: 0.5s;
  animation-name: showWindow;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes showWindow {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes closeWindow {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}

.closeWindow {
  animation-duration: 0.5s;
  animation-name: closeWindow;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.song-detail i {
  margin: 10px;
  font-size: 23px;
  cursor: pointer;
  opacity: 0.28;
  padding: 5px;
  border-radius: 50%;
}

.song-detail i:hover {
  background-color: rgb(176, 174, 179);
}

.content {
  display: flex;
}

.content .img-box {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 5vh;
}

.player-bar {
  position: absolute;
  left: 40%;
  top: -10px;
  z-index: 10;
  transform: rotate(-25deg);
  transform-origin: 12px 12px;
  transition: 1s;
}

.isplay {
  transform: rotate(0);
}

.running {
  animation-play-state: running;
}

.paused {
  animation-play-state: paused;
}

.img-wrap-rotate {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.img-wrap-rotate::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

.img-wrap-rotate::before {
  content: '';
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #000;
}

.img-wrap-rotate img {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  position: relative;

  animation-name: rotation;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.song-info {
  margin-top: 1vw;
  font-size: 16px;
  opacity: 0.28;
}

.song-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.song-wrap h2 {
  font-weight: normal;
  font-size: 23px;
}
.lyric-wrap .el-scrollbar__view {
  position: relative;
  padding: 2vh 0 5vw 0;
}
.lyric-wrap {
  margin-top: 6vh;
  height: 100%;
  width: 80%;
}

.lyric-wrap p {
  padding: 18px;
  font-size: 28px;
  opacity: 0.28;
  cursor: default;
  border-radius: 12px;
}

.lyric-wrap p:hover {
  background-color: rgb(176, 174, 179);
}

.active-lyric {
  font-weight: bold;
  font-size: 15px;
  opacity: 1 !important;
}

@media screen and (max-width: 479px) {
  .content {
    display: flex;
    flex-direction: column;
  }
  .content .img-box {
    width: 50vw;
    margin-top: -2vw;
    margin-left: 25vw;
  }
  .content.song-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .img-wrap-rotate {
    width: 50vw;
    height: 50vw;
    margin: 0;
  }
  .player-bar {
    left: 60%;
    top: -6vw;
    width: 15vw;
    height: 21vw;
    transform: rotate(-25deg);
    transform-origin: top left;
    transition: 1s;
  }
  .isplay {
    transform: rotate(0);
  }
  .song-wrap {
    align-items: center;
  }
  .song-wrap h2 {
    font-size: 20px;
  }
  .song-info {
    margin-top: 2vw;
    font-size: 16px;
  }
  .lyric-wrap {
    margin-top: 5vw;
    height: 45vh;
  }

  .lyric-wrap .el-scrollbar__view {
    position: relative;
    padding: 0;
  }
  .lyric-wrap p {
    padding: 3vw 0;
    font-size: 18px;
    text-align: center;
  }
}
</style>
