<template>
  <div class="btm">
    <div class="bottom">
      <div class="music-box">
        <div class="img-wrap el-icon-arrow-up"
             @click="toSongDetail()"
             title="打开音乐详情页"
             onselectstart="return false">
          <img :src="globalMusicInfo.imgUrl ? globalMusicInfo.imgUrl : defaultImg">
        </div>
        <div class="music-info"
             v-show="globalMusicInfo.songName">
          <span class="music-name"
                :title="globalMusicInfo.songName">{{globalMusicInfo.songName}}</span>

          <div class="music-singer">
            <span v-for="(singer,i) in globalMusicInfo.artistInfo"
                  :key="i">{{singer.name}} </span>
          </div>
        </div>
        <span class="music-ready"
              v-show="!globalMusicInfo.songName">空空如也</span>
      </div>
      <Audio :musicUrl="globalMusicUrl"
             @timeupdate="updateTime"
             @play="playStatus"
             @pause="pauseStatus"
             @next="next"
             @prev="prev"
             @end="next"></Audio>
      <el-tooltip content="播放队列"
                  placement="top"
                  effect="light">
        <div @click="toshowQueue()"
             ref="queue"
             :class="{'delete-queue-style bofangliebiao':this.queueStyle=='delete','add-queue-style bofangliebiao':this.queueStyle=='add','bofangliebiao':this.queueStyle=='normal'}">
          <span class="iconfont icon-yinleliebiao"></span>
          <span>{{musicQueue.length}}</span>
        </div>
      </el-tooltip>

    </div>
    <transition name="fade">
      <Queue style="z-index: 16"
             v-show="showQueue"></Queue>
    </transition>
    <SongDetail :show="show"></SongDetail>
  </div>
</template>

<script scoped>
import SongDetail from '@/components/SongDetail'
import Queue from '@/components/Queue'
import Audio from '@/components/Audio'
export default {
  data () {
    return {
      defaultImg: require('@/assets/img/defaultImg.png'),
      musicUrl: '',
      currentTime: 0,
      showQueue: false,
      show: true
    }
  },
  components: {
    Audio,
    Queue,
    SongDetail
  },
  mounted () {
    const that = this
    if (that.$refs.queue.getBoundingClientRect) {
      const pos = {
        top: Math.floor(that.$refs.queue.getBoundingClientRect().top),
        left: Math.floor(that.$refs.queue.getBoundingClientRect().left)
      }
      that.$store.commit('changeQueuePos', pos)
    }
  },
  computed: {
    globalMusicUrl () {
      return this.$store.state.globalMusicUrl
    },
    globalMusicInfo () {
      return this.$store.state.globalMusicInfo
    },
    musicQueue () {
      return this.$store.state.musicQueue
    },
    nowIndex () {
      return this.$store.state.nowIndex
    },
    queueStyle () {
      return this.$store.state.queueStyle
    }
  },
  methods: {
    toSongDetail () {
      if (this.globalMusicUrl) {
        this.show = !this.show
      } else {
        this.$message.warning({
          showClose: true,
          message: '还没有要播放音乐呐',
          center: true
        })
      }
    },
    updateTime (e) {
      this.currentTime = e.target.currentTime
    },
    pauseStatus () {
      this.$store.commit('changeMusicStatus', true)
    },
    playStatus () {
      this.$store.commit('changeMusicStatus', false)
    },
    toshowQueue () {
      this.showQueue = !this.showQueue
    },
    next () {
      if (this.musicQueue.length === 0) {
        this.$message.warning({
          message: '播放列表是空的~',
          center: true,
          showClose: true
        })
      } else {
        if (this.musicQueue.length === 1) {
          // 若播放列表只有一首歌 则单曲循环
          this.currentTime = 0
          const musicUrl = this.globalMusicUrl
          this.$store.commit('changeMusicUrl', '')
          setTimeout(() => {
            this.$store.commit('changeMusicUrl', musicUrl)
          }, 0)
        } else {
          const ids = []
          for (const item of this.musicQueue) {
            ids.push(item.id)
          }
          const nowIndex = ids.indexOf(this.globalMusicInfo.id)
          const nextIndex = (nowIndex + 1) % this.musicQueue.length
          this.$store.commit('changeNowIndex', nextIndex)
          this.$store.commit('changeMusicStatus', false)
        }
      }
    },
    prev () {
      if (this.musicQueue.length === 0) {
        this.$message.warning({
          message: '播放列表是空的~',
          center: true,
          showClose: true
        })
      } else {
        if (this.musicQueue.length === 1) {
          this.currentTime = 0
          const musicUrl = this.globalMusicUrl
          this.$store.commit('changeMusicUrl', '')
          setTimeout(() => {
            this.$store.commit('changeMusicUrl', musicUrl)
          }, 0)
        } else {
          const ids = []
          for (const item of this.musicQueue) {
            ids.push(item.id)
          }
          const nowIndex = ids.indexOf(this.globalMusicInfo.id)
          const prevIndex =
            (nowIndex - 1) % this.musicQueue.length < 0
              ? ((nowIndex - 1) % this.musicQueue.length) +
                this.musicQueue.length
              : (nowIndex - 1) % this.musicQueue.length
          this.$store.commit('changeNowIndex', prevIndex)
          this.$store.commit('changeMusicStatus', false)
        }
      }
    }
  },
  watch: {
    currentTime () {
      this.$store.commit('changeCurrentTime', this.currentTime)
    },
    globalMusicInfo () {
      const obj = {
        musicUrl: this.globalMusicUrl,
        ...this.globalMusicInfo
      }
      this.$store.commit('changeMusicQueue', obj)
    }
  }
}
</script>

<style>
.add-queue-style {
  animation-name: addAni;
  animation-duration: 0.3s;
  animation-direction: alternate-reverse;
  transform: scale(1);
}

@keyframes addAni {
  to {
    color: #ff0000;
    transform: scale(1.5);
  }
}

.add-queue-style::before {
  content: '+1';
  position: absolute;
  top: -8px;
  left: -20px;
  transform: scale(0.8);
  font-weight: bold;
}

.delete-queue-style {
  animation-name: deleteAni;
  animation-duration: 0.5s;
  animation-direction: alternate-reverse;
  transform: scale(1);
}

@keyframes deleteAni {
  to {
    color: green;
    transform: scale(1.5);
  }
}

.delete-queue-style::before {
  content: '-1';
  position: absolute;
  top: -8px;
  left: -20px;
  transform: scale(0.8);
  font-weight: bold;
}

.bottom {
  display: flex;
  align-items: center;
  background-color: #f1f3f4;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.3);
  max-height: 65px;
  position: relative;
  z-index: 17;
}

.bottom audio {
  width: 100%;
  outline: none;
}

.music-box {
  display: flex;
  padding: 2px;
  margin-left: 28px;
  box-sizing: border-box;
}

.music-box .img-wrap {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  cursor: pointer;
  position: relative;
}

.music-box .img-wrap::before {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 5px;
  display: none;
}

.music-box .img-wrap:hover::before {
  display: flex;
}

.music-box .img-wrap img {
  height: 100%;
  border-radius: 5px;
}

.music-name {
  font-size: 14px;
  line-height: 30px;
  width: 150px;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.music-singer {
  margin-top: 3px;
  height: 20px;
  font-size: 12px;
  width: 150px;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bofangliebiao {
  margin-right: 100px;
  font-size: 12px;
  cursor: pointer;
  z-index: 19;
}

.bofangliebiao span:nth-of-type(1) {
  font-size: 22px;
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.5s;
}
.fade-enter,
.fade-leave-active {
  transform: translate3d(0, 100%, 0);
}
@media screen and (max-width: 479px) {
  .bottom {
    width: 100vw;
    max-height: 60px;
  }
  .music-box {
    margin-left: 0;
    width: 45vw;
  }
  .music-box .img-wrap {
    width: 14vw;
    height: 14vw;
    margin-top: 0.5vw;
    margin-right: 0.5vw;
  }
  .music-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .music-name {
    width: 25vw;
  }
  .music-singer {
    font-size: 14px;
    width: 17vw;
  }
  .bofangliebiao {
    margin: 0 1vw;
  }
  .music-ready {
    font-size: 14px;
    width: 25vw;
    margin-top: 2vw;
    line-height: 30px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
